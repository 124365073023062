import * as React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not Found">
      <p>This URL doesn't exist.</p>
    </Layout>
  )
}

export default NotFoundPage
